body{
  --bkg-color: white;
  --bkg-color-two: white;
  --bkg-color-three: white;
  --text-primary: black;
  --text-dim: lightgray;
  --text-two: white;
  --text-four: black;
  --btn-one: #26a69a;
  --btn-two: #e0e0e0;
  --btn-three: #cfcfcf;
  --pressed-btn: #789;
  --highlight-text: black;
  --highlight-bg: yellow;
  --hover-color: rgb(235, 235, 235);
  --border-color: rgb(238, 238, 238);
  --export-pdf-btn-bg: #f5f5f5;
  --export-pdf-btn-text: black;
  --filters-btn: #9e9e9e;
  --filters-text-color: white;
  --filters-btn-disabled-color: gray;
  --filters-btn-disabled-bg: lightgray;
  --search-btn-color: white;
  --detail-scrollbar-color: lightgray;


}


body.dark-mode {
  --bkg-color: #0b101b;
  --bkg-color-two: #1b293d;
  --bkg-color-three: #06121f;
  --text-primary: rgb(255, 255, 255);
  --text-dim: gray;
  --text-two: black;
  --text-three: black;
  --text-four: rgb(241, 241, 241);
  --btn-one: #b9b6ab;

  /* --btn-two: rgb(128, 140, 148); */
  --btn-two: #2b3f50;
  --btn-three: #6f839e;
  --highlight-bg: #b9b6ab;
  --highlight-color: black;
  --hover-color:rgb(145, 170, 199);
  --border-color: rgb(96, 99, 136);

  --filters-btn: #162a3a;
  --filters-text-color: white;
  --pressed-btn: #6f839e;
  --search-btn-color: black;
  --filters-btn-disabled-color: rgb(94, 94, 94);
  --filters-btn-disabled-bg: rgb(49, 49, 49);
  --export-pdf-btn-bg: #6f839e;
  --export-pdf-btn-text: black;

  --detail-scrollbar-color: rgb(58, 58, 58);
}

.cat-btns, .q-select-btns{
  color: var(--text-four) !important;
  background-color: var(--btn-two) !important;
}

.cat-btns:hover, .q-select-btns:hover{
  color: black !important;
  background-color: var(--btn-three) !important;
}

#adv-filters-btn{
  color: var(--filters-text-color) !important;
  background-color: var(--filters-btn) !important;
}

#adv-filters-btn:disabled{
  color: var(--filters-btn-disabled-color) !important;
  background-color: var(--filters-btn-disabled-bg) !important;
}
#adv-filters-btn:disabled:hover{
  cursor:not-allowed !important;
}

#search_field{
  color: var(--text-primary)
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  box-shadow: #26a69a;
  -webkit-box-shadow: 0 0 0 30px var(--bkg-color-three) inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: var(--text-primary) !important;
}

#search-btn{
  color: var(--search-btn-color);
  background-color: var(--btn-one);
}

#pdf-export-btn{
  background-color: var(--export-pdf-btn);
}

.MuiPaper-root{
  color: var(--text-primary) !important;
  background-color: var(--bkg-color-two) !important;
}
.MuiDialogTitle-root{
  background-color: var(--bkg-color-two) !important;
}

.pdf-alerts .MuiPaper-root{
  background-color: rgb(237, 247, 237) !important;
}

html, body{
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bkg-color) !important;
  color: var(--text-primary) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "NotoNastaliqFont";
  font-style: normal;
  src: url("NotoNastaliqUrdu-Regular.ttf");
}

@font-face {
  font-family: "NotoNaskhFont";
  src: url("NotoNaskhArabic-Regular.ttf");
}

.height-100{ height: 100%; }
.height-95{height: 95vh;}
.height-20{ height: 20vh; }
.padding-top-40{ padding-top: 40vh; }
.padding-top-10{ padding-top: 10vh; }
.padding-top-5 {padding-top: 5vh;}
.padding-left-3p{padding-left: 3px;}
.padding-right-3p{padding-right: 3px;}
.padding-zero{padding:0 !important}
.margin-zero{margin:0}
.padding-bottom-5{ padding-bottom: 5vh; }
.margin-auto{ margin:auto; }
.margin-top-10{margin-top: 10vh}
.margin-top-5{margin-top: 5vh;}
.margin-top-2{margin-top: 2vh}
.margin-right-1{margin-right: 1vw}
.margin-left-5p{margin-left:5px;}
.margin-left-40p{margin-left:40px}
.margin-top-20p{margin-top:20px;}
.margin-top-neg3{margin-top: -3vh;}
.border-black-1p{ border: 1px solid black; }
.width-50{ width:50% }
.display-block{ display:block }
.display-flex {display: flex; flex-direction: row; flex-wrap: wrap;}
.width-content{ width: fit-content; }
.text-align-center{text-align: center;}
.text-align-left{text-align: left;}
.direction-rtl{direction:rtl;}

.urdu-font{
  font-family: "NotoNastaliqFont";
  font-size: 0.9rem;
}
.urdu-text{
  font-family: "NotoNastaliqFont";
  text-align: justify;
  text-align-last: right !important;
  line-height: 2.8rem;
  font-size: 0.92rem;
  word-spacing: 0.17rem;
}

.arabic-font{
  font-family: "NotoNaskhFont";
  font-size: 0.9rem;

}

.arabic-text{
  font-family: "NotoNaskhFont";
  font-size: 1.5rem;
  text-align-last: right !important;
}


.detail-content{
  min-height: 85%;
}

.hQArabicText{
  font-family: "ArabicFont";
  font-size: 2.1rem;
  text-align: center;
}

.App{
  height: 100%;
}

/* Login page */
.login-btn{
  display: block;
  width: 250px;
  text-transform: none;
}
.login-text{
  display: block;
  width: 250px;
  text-transform: none;
  color: gray;
  padding-left: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}
.login-footer{
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  padding-bottom: 6px;
}

.login-footer a{
  color: gray;
}

.login-footer p{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  padding:0;
}
/* ******* */

/* search sections */
.input-field{
  width: 95%;
}

#search-btn{
  border-radius: 20px;
  width: 100px;
  height: 40px;
}

/* history */
.history-section{
  margin-top: 8px;
  padding: 5px;
  padding-bottom: 20px;
  overflow: hidden;
  width: 200px;
  min-height: 60vh;
}
.history-item{
  width: inherit;
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.history-item:hover{
  cursor: pointer;
  background-color: var(--hover-color);
}
.history-item span:first-child{
  font-size: 1.1rem;
  color: var(--text-dim);
}
.history-item span:last-child{
  font-size: smaller;
}

.blue-link:hover{
  text-decoration: underline;
  cursor: pointer;
}

.black-link{
  color: black;
}


/* Result Item */
.result-item{
  margin-top: 10px;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 5px;
  padding: 8px 6px 8px 6px;
  background-color: var(--bkg-color-two);
  box-shadow: var(--result-item-shadow);
  -webkit-box-shadow: var(--result-item-shadow);
}

.MuiDialogContent-root::-webkit-scrollbar{
  width: 8px !important;
}

.MuiDialogContent-root::-webkit-scrollbar-track{
  background-color: transparent;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb{
  background-color: var(--detail-scrollbar-color);
}

.MuiDialog-paperWidthMd{
  height: 90vh;
}

.result-item .snippet{
  margin: 0;
  padding: 0;
  text-align: justify;
  cursor: pointer;
}

.result-item .reference-info p{
  direction: ltr;
  text-align: left;
  margin: 0;
  padding: 8px 0 5px 0;
  font-size: 1rem;
  font-weight: bold;
}

#OpenOriginalLinkButton{
  color: var(--text-two);
  background-color: var(--btn-one);
  float: right;
}

#openInBookBtn{
  color: var(--text-two);
  background-color: var(--btn-one);
  float: right;
}
#openInPDFBtn{
  color: var(--text-two);
  background-color: var(--btn-one);
  float:right;
  margin-right:10px;
}

/* ****** */


/* Result list */
.result-list{
  margin-top: 10px;
  width: 95%;
}
/* ***** */

/* Result details */
.highlight{
  color: var(--highlight-text);
  background-color: var(--highlight-bg);
}

.modal-close{
  position: absolute;
  top: 0;
  right: 0;
  margin-right:0;
  display: block;
}
/* ****** */

/* Filters */
.pressed-btn{
  color: var(--text-three) !important;
  background-color: var(--pressed-btn) !important;
}

#filters-container{
  display: none;
}

.filters-section:not(:first-child){
  margin-top: 15px;
}

.filters-section{
  margin-bottom: 8px;
  border-bottom: 1px solid lightgray;
  width: 100%;
}

.checkboxes label{
  margin-right: 20px;
}

.filters-flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filters-flex div{
  width: 250px;
  padding-right: 5px;
}

legend{
  margin-bottom: 10px;
}

.filters-flex div label{
  width: 250px
}

.filters-flex div span{
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filters-flex .volumes{
  margin-left: 5px;
}
.filters-flex .volumes span{
  font-size: small;
}

.volume-section label{
  width: 120px;
}

.MuiFormControlLabel-label{
  width: 250px;
  overflow: hidden;
}
.MuiFormControlLabel-root{
  width: 250px
}
/* .MuiDialogContent-root {
  overflow-y: visible !important;
} */

.coll-item{
  width: 250px;
  margin-bottom: 8px;
  padding-right: 5px;
}

.MuiDialogTitle-root{
  background-color: white;
}


.MuiAccordion-root{
  margin-top: 15px
}
.MuiAccordionSummary-root.Mui-expanded{
  margin-top: 15px;
}

.coll-vol{
  width: 40px;
  font-size: 0.8rem;
}

.apply-filters-btn{
  color: var(--text-two);
  background-color: var(--btn-one);
  position: sticky;
  bottom: 0;
  z-index: 10;
  margin: auto;
  display: block;
}

.apply-filters-container{
  position: sticky;
  bottom: 0px;
  z-index: 10;
  background-color: var(--bkg-color-two);
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.accordian-content{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded{
  overflow-y: visible;
}

/* ******* */


/* logo */
.logo-large{
  margin: auto;
  padding: 0;
  padding-top: 5px;
  display: block;
  width: 80vw;
  max-width: 460px;
}

.logo-small{
  padding-top: 4px;
  padding-bottom: 4px;
  width: 10vw;
  margin-left: 40%;
}
/* **** */

#pdf-export-btn{
  background-color: var(--export-pdf-btn-bg);
  color: var(--export-pdf-btn-text) !important;
  cursor: pointer;
  display: block;
}

#pdf-export-btn:hover{
  color: blue;
}

.settings-list li{
  margin-top: 10px;
}

/* main layout */
.main-column{
  margin-top:30px;
}
.right-column{
  display: none;
}

/* Desktop */
@media only screen and (min-width: 993px) {
  .history-section{
    padding-top: 10px;
    margin-right: 10px;
    width: 100%;
    border-right: 1px solid var(--border-color);
    height: 45vh;
  }
  .logo-small{
    margin: 0;
    padding-top: 10px;
    width: 170px;
  }
  .main-column{
    margin-top: 40px;
  }
  .input-field{
    width: 70%;
  }
  .right-column{
    display: block;
  }
}


.dis-button-with-popup {
  position: relative;
 max-width: 100%;
}

.dis-button {
  background-color:var(--btn-three); /* No background color */
  border: none; /* No border */
  outline: none; /* Remove default focus outline */
  cursor: pointer;
  font-size: 16px;
  padding: 0.2rem;
  max-width: 100%;
  min-width: 100%;
  color: var(--text-four);
  text-align: center;

}
.dis-button:hover {
  background-color: #778899; /* Change color to transparent when clicked */
}
.dis-button:active {
  background-color: #e0e0e0;
}
.dis-button:focus {
  background-color: #e0e0e0;
}

.dis-popup {
  position: absolute;
  bottom:100%;
  left: 0;
  background-color: var(--bkg-color);
  border: 1px solid  #e0e0e0;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-width:90%; /* Adjust popup width as needed */
  border-radius: 8px; /* Add rounded corners */
  margin: 1rem;
  max-height: 70vh;  
  min-height: fit-content;
  overflow-y: auto; 
  color: var(--text-primary);
  /* max-height: fit-content; */
}
/* Hide scrollbar for Webkit browsers */
.dis-popup::-webkit-scrollbar {
  display: none;
}
.dis-popup p {
  margin: 1rem;
  /* text-align: center; */
  justify-content: center;
  
}

.dis-close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding-left: 5px;
  cursor: pointer;
  font-size: 20px; /* Increase the font size for a bigger close button */
}
.feed-submit-btn {

  border-radius: 20px; /* Remove the quotes around the pixel value */
  margin-top: 16px; /* Remove the quotes around the pixel value */
  color: var(--search-btn-color);
  background-color: var(--btn-one);
  width: 110px;
  height: 40px;
}
.feed-subject{
  margin: 0,0,0,0;
  border-radius: 30px;
}
.feed-feedback{
  border-radius: 10px;
}
.icon-link{
  padding-left: 0%;
  margin-left: 0px;
  font-size: larger;
  color: #d3d3d3;
  font-weight: bold;
  align-items:left;
}